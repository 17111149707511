<template>
  <div
    id="myBlock"
    data-vide-bg="/media/video/Ipad"
    class="div-100"
    style="font-size: 130% !important"
  >
    <div
      style="background: url(/media/video/Ipad.jpg); background-size: 100% 100%; background-repeat: no-repeat; position: absolute; top: 0; right: 0; width: 100%; height: 100%; z-index: -2;"
    ></div>

    <div class="center-container div-100">
      <div class="w3ls-header">
        <div class="header-main">
          <div class="videologin">
            <h2>商 品 系 统</h2>
            <div class="header-bottom">
              <div class="header-right w3agile">
                <div class="header-left-bottom agileinfo">
                  <form
                    class="form"
                    novalidate="novalidate"
                    id="kt_login_signin_form"
                  >
                    <div class="icon1 form-group">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <input
                        type="text"
                        placeholder="用户名"
                        name="email"
                        ref="email"
                        v-model="form.email"
                      />
                    </div>
                    <div class="icon1 form-group">
                      <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                      <input
                        type="password"
                        placeholder="密码"
                        name="password"
                        ref="password"
                        v-model="form.password"
                        autocomplete="off"
                      />
                    </div>
                    <label class="anim">
                      <input
                        type="checkbox"
                        class="checkbox"
                        style="display: initial"
                      />
                      <span> 记住我 </span>
                    </label>
                    <a href="#" class="forgot">忘记密码？</a>
                    <div class="bottom">
                      <button v-bind:disabled="submitDisabled"
                        ref="kt_login_signin_submit"
                        type="submit"
                      >
                        登 录
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="clear"></div>
        </div>

        <!-- copyright start here -->
        <div class="copyright">
          <p>
            © 2021 All rights reserved | Design by
            <a href="#">LCX</a>
          </p>
        </div>
        <!--copyright end here-->
      </div>
      <!--header end here-->
    </div>
  </div>
</template>

<style lang="scss">

@import "@/assets/sass/pages/login_v2/login2.scss";

@import "~@fortawesome/fontawesome-free/css/fontawesome.css";

.fv-help-block {
    font-size: 14px !important;
    background-color: #ed1b2e;
    color: white !important;
    line-height: 40px;
}
.fv-help-block::before {
    content: "\f057";
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    font-weight: bold;
    margin: 7px;
    // font-family: 'Font Awesome 5 Free';
    // font-weight: 900;
}
</style>

<script>


import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-v2",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      submitDisabled: false
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"])
  },
  mounted() {

    // require("./jquery.vide.js"); 

    require("@/assets/js/pages/login_v2/jquery.vide.min.js");
    
    // window.$('#myBlock').vide('/media/video/Ipad');

    this.submitDisabled = false;

    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "用户名必填"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "密码必填"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {

      this.submitDisabled = true;

      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { name: email, password: password })
          // go to which page after successfully login
          .then(() => this.$router.push({ name: "order-list" }))
          .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        this.submitDisabled = false;
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "用户名密码必填！",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    }
  }
};
</script>
